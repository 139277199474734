// This returns a function we can use to resize images for better optimization
export function useResize() {
  const { isMobile } = useDevice();
  return (url: string, width?: number) => {
    if (width != null) {
      return url + `?w=${width}`;
    } else if (isMobile) {
      return url + '?w=736';
    } else {
      return url;
    }
  };
}
